import React from 'react'
import { Wrapper } from 'src/components/layout/wrapper'

export default () => {
  return (
    <Wrapper subtitle='Kontakt'>
      <h1>Vielen Dank</h1>

      <p>
        Vielen Dank für die Anfrage. Wir werden uns so schnell wie möglich bei Ihnen melden!
      </p>
    </Wrapper>
  )
}
